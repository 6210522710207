import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"
import styled from "styled-components"
import Head from "../components/Head"
import bgImgHeader from "../../assets/images/pic13.jpg"

const E = {
  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    ul {
      margin-top: 45px;
    }

    .text {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      @media (max-width: ${breakpoints.medium}px) {
        max-width: 90%;
        flex-basis: 90%;
        padding: 0 5px;
        padding-bottom: 20px;
      }

      h3 {
        font-weight: bold;
        margin-bottom: 20px;
      }

      p {
        font-weight: 300;
        padding-left: 30px;
      }
    }
  `,

  Section: styled.section`
    margin: 150px 0;
  `,
}

const ContactPage = () => (
  <Layout>
    <Seo title="לינקים חיצוניים" />

    <Head title="לינקים חיצוניים" img={bgImgHeader} />

    <E.Section>
      <div className="container">
        <E.Row>
          <div className="text">
            <h3>לינקים חיצוניים</h3>
            <p>מכאן ניתן לגשת לאיזורים אישיים נוספים:</p>
          </div>
          <div className="form">
            <ul>
              <li>
                <a 
                  href="https://portal.roeto.co.il/ILAN/" 
                  target="_blank"
                  rel="noreferrer"
                >
                  מידע אישי ללקוחות רות אילן
                </a>
              </li>
            </ul>
          </div>
        </E.Row>
      </div>
    </E.Section>
  </Layout>
)

export default ContactPage
